import Vue from 'vue';
import Router from 'vue-router';
// import menuId from '@/constants/menuId';
import routerPath from './routerPath';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: routerPath.CARD_INFOMATION,
      name: 'CardInfomation',
      meta: { 
        pageTitle:'카드 이용안내',
        requiresAuth: false
      },
      component: () => import('@/views/card/view/CardInfomation.vue'),
    },
    {
      path: routerPath.CARD_INFOMATION_WEB,
      name: 'CardInfomationWeb',
      meta: { 
        pageTitle:'카드 이용안내',
        requiresAuth: false
      },
      component: () => import('@/views/card/view/CardInfomation.vue'),
    },
    {
      path: routerPath.CARD_REGISTER,
      name: 'CardRegister',
      meta: { 
        pageTitle:'퍼핀카드 신청',
        requiresAuth: false
      },
      component: () => import('@/views/card/view/CardRegister.vue'),
    },
    {
      path: routerPath.CARD_MISSING,
      name: 'CardMissing',
      meta: { 
        pageTitle:'분실신고',
        requiresAuth: false
      },
      component: () => import('@/views/card/view/CardMissing.vue'),
    },
    {
      path: routerPath.TERM_INDEX,
      name: 'TermIndex',
      meta: { 
        pageTitle:'약관 모아보기',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/TermIndex.vue'),
    },
    {
      path: routerPath.TERM_IDENTITY_VERIFY_TERMS,
      name: 'IdentityVerifyTerms',
      meta: { 
        pageTitle:'통신사 본인확인 서비스 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/IdentityVerifyTerms.vue'),
    },
    {
      path: routerPath.TERM_IDENTITY_VERIFY_KCB_TERMS,
      name: 'IdentityVerifyKcbTerms',
      meta: { 
        pageTitle:'KCB 본인확인 서비스 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/IdentityVerifyKcbTerms.vue'),
    },
    {
      path: routerPath.TERM_IDENTITY_VERIFY_PRIVACY_AGREEMENT,
      name: 'IdentityVerifyPrivacyAgreement',
      meta: { 
        pageTitle:'개인정보 수집, 이용 및 위탁 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/IdentityVerifyPrivacyAgreement.vue'),
    },
    {
      path: routerPath.TERM_IDENTITY_VERIFY_INDENTIFICATION_AGREEMENT,
      name: 'IdentityVerifyIdentificationAgreement',
      meta: { 
        pageTitle:'고유식별정보 처리 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/IdentityVerifyIdentificationAgreement.vue'),
    },
    {
      path: routerPath.TERM_IDENTITY_VERIFY_3RD_PARTIES,
      name: 'IdentityVerify3rdParties',
      meta: { 
        pageTitle:'알뜰폰 개인정보 제공 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/IdentityVerify3rdParties.vue'),
    },
    {
      path: routerPath.TERM_GUARDIAN_AGREEMENT_FOR_SIGNUP,
      name: 'GuardianAgreementForSignup',
      meta: { 
        pageTitle:'서비스가입',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/GuardianAgreementForSignup.vue'),
    },
    {
      path: routerPath.TERM_GUARDIAN_AGREEMENT_FOR_ACCOUNT_DELETION,
      name: 'GuardianAgreementForAccountDeletion',
      meta: { 
        pageTitle:'서비스탈퇴',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/GuardianAgreementForAccountDeletion.vue'),
    },
    {
      path: routerPath.TERM_GUARDIAN_AGREEMENT_FOR_ISSUE_CARD,
      name: 'GuardianAgreementForIssueCard',
      meta: { 
        pageTitle:'카드발급',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/GuardianAgreementForIssueCard.vue'),
    },
    {
      path: routerPath.TERM_CONSENT_FOR_MARKETING,
      name: 'ConsentForMarketing',
      meta: { 
        pageTitle:'광고 마케팅 수신 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/ConsentForMarketing.vue'),
    },
    {
      path: routerPath.TERM_CONSENT_TAX_SELTTLEMENT,
      name: 'ConsentForTaxSelttlement',
      meta: { 
        pageTitle:'퍼핀카드 소득공제 신청 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/ConsentForTaxSelttlement.vue'),
    },
    {
      path: routerPath.TERM_PRIVACY_POLICY,
      name: 'PrivacyPolicy',
      meta: { 
        pageTitle:'개인정보 처리방침',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/PrivacyPolicy.vue'),
    },
    {
      path: routerPath.TERM_PRIVACY_POLICY_230327,
      name: 'PrivacyPolicy230327',
      meta: { 
        pageTitle:'개인정보 처리방침',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/PrivacyPolicy230327.vue'),
    },
    {
      path: routerPath.TERM_PRIVACY_POLICY_240227,
      name: 'PrivacyPolicy240227',
      meta: { 
        pageTitle:'개인정보 처리방침',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/PrivacyPolicy240227.vue'),
    },
    
    {
      path: routerPath.TERM_PRIVACY_AGREEMENT,
      name: 'PrivacyAgreement',
      meta: { 
        pageTitle:'개인정보 수집 이용 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/PrivacyAgreement.vue'),
    },
    {
      path: routerPath.TERM_PRIVACY_3RD_PARTIES,
      name: 'Privacy3rdParties',
      meta: { 
        pageTitle:'개인정보 제3자 제공 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/Privacy3rdParties.vue'),
    },
    {
      path: routerPath.TERM_PRIVACY_AGREEMENT_SCHOOL_MEAL,
      name: 'PrivacyAgreementSchoolMeal',
      meta: { 
        pageTitle:'개인정보 수집 · 이용 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/PrivacyAgreementSchoolMeal.vue'),
    },
    {
      path: routerPath.TERM_PRIVACY_AGREEMENT_BILL,
      name: 'PrivacyAgreementBill',
      meta: { 
        pageTitle:'개인정보 수집 · 이용 동의',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/PrivacyAgreementBill.vue'),
    },
    {
      path: routerPath.TERM_ELECTRONIC_FINANCIAL_TERMS,
      name: 'ElectronicFinancialTerms',
      meta: { 
        pageTitle:'전자금융거래 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/ElectronicFinancialTerms.vue'),
    },
    {
      path: routerPath.TERM_FIRFIN_MONEY_TERMS,
      name: 'FirfinMoneyTerms',
      meta: { 
        pageTitle:'퍼핀머니 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/FirfinMoneyTerms.vue'),
    },
    {
      path: routerPath.TERM_FIRFIN_PAY_TERMS,
      name: 'FirfinPayTerms',
      meta: { 
        pageTitle:'퍼핀페이 서비스 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/FirfinPayTerms.vue'),
    },
    {
      path: routerPath.TERM_FIRFIN_CARD_TERMS,
      name: 'FirfinCardTerms',
      meta: { 
        pageTitle:'퍼핀카드 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/FirfinCardTerms.vue'),
    },
    {
      path: routerPath.TERM_RAILPLUS_TERMS,
      name: 'RailplusTerms',
      meta: { 
        pageTitle:'레일플러스 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/RailplusTerms.vue'),
    },
    {
      path: routerPath.TERM_EZL_TERMS,
      name: 'EzlTerms',
      meta: { 
        pageTitle:'이동의즐거움 서비스 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/EzlTerms.vue'),
    },
    {
      path: routerPath.TERM_EZL_ELECTRONIC_FINANCIAL_TERMS,
      name: 'EzlElectonicFinancialTerms',
      meta: { 
        pageTitle:'이동의즐거움 전자금융거래 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/EzlElectonicFinancialTerms.vue'),
    },
    {
      path: routerPath.TERM_BILL,
      name: 'Bill',
      meta: { 
        pageTitle:'퍼핀 유료서비스 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/Bill.vue'),
    },
    {
      path: routerPath.TERM_BILL_IMWEB,
      name: 'BillImweb',
      meta: { 
        pageTitle:'퍼핀 유료서비스 이용약관',
        requiresAuth: false
      },
      component: () => import('@/views/terms/view/BillImweb.vue'),
    },
    
    {
      path: routerPath.HELP,
      name: 'Help',
      meta: { 
        pageTitle:'고객센터',
        requiresAuth: false
      },
      component: () => import('@/views/help/view/Help.vue'),
    },
    {
      path: routerPath.ADDRESS,
      name: 'Address',
      meta: { 
        pageTitle:'주소검색',
        requiresAuth: false
      },
      component: () => import('@/views/address/view/AddressPopup.vue'),
    },
    {
      path: routerPath.DEDUCTIONS_AND_EXEMPTIONS_INFO,
      name: 'DeductionsAndExemptionsInfo',
      meta: { 
        pageTitle:'소득공제 신청 안내',
        requiresAuth: false
      },
      component: () => import('@/views/etc/deductionsAndExemptionsInfo/view/DeductionsAndExemptionsInfo.vue'),
    },
    {
      path: routerPath.EMPTY,
      name: 'Empty',
      meta: { 
        // pageTitle:'소득공제 신청 안내',
        requiresAuth: false
      },
      component: () => import('@/views/empty/view/Empty.vue'),
    },
    { path: '*', redirect: routerPath.HELP },
  ]
});

export default router
